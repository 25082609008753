import React from 'react';

import Checkbox from '../../Checkbox';

import { IElement } from '../../../../interfaces/IElement';

export default function ElementChildChild(props: { elementChildChild: IElement }) { // A list of choices for the element
    const { elementChildChild } = props;
    
    return (
        <div className="element-child-child-container">

            <Checkbox />

            <p key={elementChildChild.id} className="element-child-child">
                {elementChildChild.amount} x {elementChildChild.externalName}
            </p>

        </div>
    )
}