import React from 'react';

import SummaryElement from './SummaryElement';

import { IOrder } from '../../interfaces/IOrder';

import { summarizedElements } from '../../functions/actions/summary';

export default function SummaryList(props: { filteredOrders: Array<IOrder>}) {
    const { filteredOrders } = props; 

    const summarizedElementsList = summarizedElements(filteredOrders);
    
    return (
        <div className="summary-list">

          { summarizedElementsList.map(function(summaryElement) {
            return <SummaryElement key={summaryElement.id} element={summaryElement} />
          })}

        </div>
      );
}
