import React from 'react';

import moment from 'moment';

import { useAppSelector } from '../../../redux/hooks';

import Counter from './Counter';
import OrderRunButton from '../OrderRunButton';

import { moduleSettings } from '../../../settings/modules';
import { IOrder } from '../../../interfaces/IOrder';
import { IModule } from '../../../interfaces/IModule';

export default function OrderHeader(props: { order: IOrder, handleRunClick: () => void }) {
    const { order } = props;
    const { handleRunClick } = props;

    const { modules } = useAppSelector((state) => state); 

    const module = modules.find((m: IModule) => m.id === order.moduleId);
    const settings = moduleSettings[module ? module.type : "default"] || moduleSettings["default"];

    return (
        <div className="order-header-section">

            <div className="order-header">
                <div className="order-header-left">
                    <p className="order-info-text">

                        { settings.orderReadyTime && <span className="order-ready-time">{moment(order.orderReadyTime).format("HH:mm")} </span> }
                        
                        { settings.customerName && order.patron && order.patron.name && <span className="order-name">{order.patron.name}</span> }

                        { settings.table && order.table && <span className="order-table">{order.table.name}</span> }

                        { settings.counter && <Counter orderTimeUtc={order.orderTimeUtc} /> }

                    </p>
                </div>
                <div className="order-header-right">
                    <OrderRunButton handleRunClick={handleRunClick} />
                </div>
            </div>

            <div className="order-receipt-number-container">
                <p className="order-receipt-number">#{order.receiptId}</p>
            </div>

        </div>
    )
}