import React, { useState } from 'react';

import moment from 'moment';

import { useAppSelector } from '../redux/hooks';

import NavTabs from './NavTabs';
import OrderList from './Orders/OrderList';
import SummaryList from './Summary/SummaryList';

import { IOrder } from '../interfaces/IOrder';

import { addStateToOrders, filterOrderList } from '../functions/actions/orders';
import { kdsStates } from '../settings/app';

export default function OrderView() {
    const { orders } = useAppSelector((state) => state); 

    const ordersWithStates = addStateToOrders(orders); // Adding states to orders to avoid looping through multiple times 
    var filteredOrders = filterOrderList(ordersWithStates).filter((o: IOrder) => o.stateId === kdsStates.READY ).sort((a: IOrder, b: IOrder) => moment(b.lastModifiedUtc).valueOf() - moment(a.lastModifiedUtc).valueOf());

    var [chosenNavTabName, setChosenNavTabName] = useState("orders");
    
    return (
        <div className="order-view">

            <NavTabs 
              chosenNavTabName={chosenNavTabName} 
              handleNavTabChange={(newValue) => setChosenNavTabName(newValue)} 
            />

            { chosenNavTabName === "orders" && 
                <OrderList filteredOrders={filteredOrders} /> 
            }

            { chosenNavTabName === "summary" && 
                <SummaryList filteredOrders={filteredOrders} /> 
            }

          </div>
      );
}
