import React from 'react';

import Checkbox from '../../Checkbox';
import Element from '../Elements/Element';

import { IElement } from '../../../../interfaces/IElement';

export default function Product(props: { element: IElement }) {
    const { element } = props;

    return (
        <div className="order-element-container">

            <Checkbox />

            <Element element={element} />

        </div>
    )
}