import React from 'react';

import { useAppDispatch } from '../../../redux/hooks';
import { updateElementState } from '../../../redux/actions';

import OrderHeader from '../OrderHeader/OrderHeader';
import ProductList from './ProductList/ProductList';

import { getElementIds } from '../../../functions/actions/orders';

import { IElement } from '../../../interfaces/IElement';
import { IOrder } from '../../../interfaces/IOrder';

export default function ProductOrder(props: { order: IOrder }) {
    const { order } = props;

    const dispatch = useAppDispatch();
    
    var productsToShow: Array<IElement> = order.elements;

    var elementIds = getElementIds(productsToShow); // Looping all elements, including children 

    function handleRunClick() {
        dispatch(updateElementState(elementIds.ids, order.id)); // The button will not be clicked unless all elements are in ready 
    }

    return (
        <div className="order product">

            <OrderHeader 
                order={order}
                handleRunClick={handleRunClick}
            />

            <ProductList 
                elements={productsToShow}
            />

        </div>
    )
}