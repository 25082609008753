import React from 'react';

import ElementChild from './ElementChild';
import { IElement } from '../../../../interfaces/IElement';

import { kdsStates } from '../../../../settings/app';

export default function Element(props: { element: IElement }) {
    const { element } = props;

    return (
        <div className="element">
            <p className="element-name">{element.amount} x {element.externalName}</p>

            { element.children && element.children.map(function(elementChild) {
                return <ElementChild key={elementChild.id} elementChild={elementChild}  {...props} />
            })}
        </div>
    )
}