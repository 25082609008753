import moment from "moment";

import { IOrder } from "../../interfaces/IOrder";
import { IElement } from "../../interfaces/IElement";

import { kdsStates } from "../../settings/app";

export function getTimestampFromMilliseconds(s: number) { // Converting the millisecond diff to a timestamp
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;
  
    function doubleDigits(n: number) {
        return n.toString().length === 1 ? "0" + n : n;
    }

    return doubleDigits(hrs) + ':' + doubleDigits(mins) + ':' + doubleDigits(secs);
}

function filterForTime(orders: Array<IOrder>) {
    var filteredOrders = orders;

    function filtering(order: IOrder) {
        const orderReadyMoment = moment(order.orderReadyTime);
        const isToday = orderReadyMoment.isSame(moment(), 'day');

        return isToday;
    }

    // Filtering orders before today or after the filter time 
    filteredOrders = orders.filter(order => filtering(order));

    return filteredOrders;
}

export function filterOrderList(orders: Array<IOrder>) {
    var filteredOrders = orders;

    // Filtering for time so we only show future orders (+ yesterday's if any are leftover)
    filteredOrders = filterForTime(filteredOrders);

    return filteredOrders;
}

export const getElementIds = (elements: Array<IElement>) => { // Incl products and nested choices 
    var ids: Array<number> = [];
    var inReady: Array<number> = [];

    const addElementIds = (elements: Array<IElement>) => {
        elements.forEach(function(element) {

            if (element.state) { // No state means it's a modifier 
                ids.push(element.id);

                if (element.state.value === kdsStates.READY) { inReady.push(element.id); }
            } 

            if (element.children) { addElementIds(element.children); }
        });
    }

    addElementIds(elements);
    return { ids, inReady };
}

export const addStateToOrders = (orders: Array<IOrder>) => { // Setting the state for the whole order based on element states
    var ordersWithStates: Array<IOrder> = [];

    orders.forEach(function(order) {
        var orderWithState = Object.assign({}, order);

        const orderElementIds = getElementIds(order.elements);
        var elementIdsInReady = orderElementIds.inReady; 

        const stateId = elementIdsInReady.length === orderElementIds.ids.length ? kdsStates.READY : kdsStates.PRODUCTION;
        orderWithState.stateId = stateId;
        ordersWithStates.push(orderWithState);
    });

    return ordersWithStates;
}