import React from 'react';
import { useTranslation } from 'react-i18next';

export default function OrderRunButton(props: { handleRunClick: () => void }) {
    const { handleRunClick } = props;

    const { t } = useTranslation();

    return (
        <div className="run-button-container">
            <button className="button run-button" onClick={handleRunClick}>{t("orders:Done")}</button>
        </div>
    )
}