import React from 'react';

export default function Checkbox() {

    return (
        <div className="checkbox">
            <input type="checkbox" checked readOnly />
			<div className="checkmark-container">
				<svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
					<circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
				</svg>
			</div>
        </div>
    )
}