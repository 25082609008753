import { IOrder } from '../../interfaces/IOrder';
import {IElement } from '../../interfaces/IElement';
import { IElementState } from '../../interfaces/IElementState';

import { get } from "../api";
import { module_url } from "../../settings/app";

export async function getModules() {
    var modules = [];
    
    try {
        modules = await get(module_url + "/module/modules");
    } catch(error) {
        console.log(error);
    } finally {
        return modules;
    }
}

export let getUpdatedOrderElementStates = function f(originalOrder: IOrder, elementIds: Array<number>, updatedState: number) { // Updating the element states inside the order
    var order: IOrder = Object.assign({}, originalOrder);

    function updateProductElementStates(elements: Array<IElement>, elementId: number, updatedState: number) {
        elements.forEach(element => {
            if(element.id === elementId) {
                var elementState: IElementState = element.state ? element.state : {};
                elementState.value = updatedState;
                element.state = elementState;
            } else {
                if (element.children) { updateProductElementStates(element.children, elementId, updatedState); }
            }
        });
    }

    var updatedElementList = order.elements.map(e => e);
    elementIds.forEach(function(elementId) {
        updateProductElementStates(updatedElementList, elementId, updatedState);
    });

    order.elements = updatedElementList;
    return order;
}