import React from 'react';

import { IOrder } from '../../interfaces/IOrder';

import ProductOrder from './Product/ProductOrder';

export default function Order(props: { order: IOrder }) {
    const { order } = props;

    return (
        <div className="order-container list-item">
            
            <ProductOrder 
                key={order.id} 
                order={order} 
            />

        </div>
    )
}