import { AppAction } from './actions';
import { getUpdatedOrderElementStates } from '../functions/actions/redux';

import { 
  SET_LOADING,
  SET_INSTANCE,
  LOAD_MODULES,
  LOAD_ORDERS,

  ORDER_RECEIVED,
  KDS_STATE_UPDATED
} from "./actions";

import { IOrder } from '../interfaces/IOrder';

const initialState = {
  loading: false,
  instance: undefined,
  modules: [],
  orders: []
};

export default function reduce(state = initialState, action: AppAction) {
  switch (action.type) {
    
    case SET_LOADING: {
      const { loading } = action.payload;
      return {
        ...state,
        loading
      };
    }

    case SET_INSTANCE: {
      const { instance } = action.payload;

      return {
        ...state,
        instance
      };
    }

    case LOAD_MODULES: {
      const { modules } = action.payload;
      return {
        ...state,
        modules
      };
    }

    case LOAD_ORDERS: {
      const { orders } = action.payload;

      return {
        ...state,
        orders
      };
    }

    case ORDER_RECEIVED: {
      const { order } = action.payload;

      var orders: Array<IOrder> = state.orders.map(obj => obj);
      orders.unshift(order);
      
      return {
        ...state,
        orders
      };
    }

    case KDS_STATE_UPDATED: { // Handle product/ingredient state update
      const { elementIds, orderId, updatedState } = action.payload;

      var updatedOrders: Array<IOrder> = state.orders.map(obj => obj);
      // Find the order
      var orderIndex = updatedOrders.findIndex(o => o.id === orderId);
      var order: IOrder = getUpdatedOrderElementStates(updatedOrders[orderIndex], elementIds, updatedState);

      updatedOrders[orderIndex] = order;
      
      return {
        ...state,
        orders: updatedOrders
      };
    }

    default:
      return state;
  }
}